import { v4 as uuidv4 } from 'uuid';
import Arduino from '../images/skills/arduino.png';
import Bootstrap from '../images/skills/bootstrap.png';
import CSS from '../images/skills/css.png';
import Figma from '../images/skills/figma.png';
import GIT from '../images/skills/git.png';
import HTML from '../images/skills/html.png';
import Java from '../images/skills/java.png';
import JS from '../images/skills/js.png';
// import PHP from '../images/skills/php.png';
import Python from '../images/skills/python.png';
import ReactLogo from '../images/skills/react-logo.png';
import SQL from '../images/skills/sql-server.png';
import UML from '../images/skills/uml.webp';
import C from '../images/skills/C.png';
// import R from '../images/skills/R.png';
// import Selenium from '../images/skills/selenium.png';
import Soup from '../images/skills/soup.png';
import MongoDB from '../images/skills/mongodb.png';
// import NodeJS from '../images/skills/nodejs.png';
import ExpressJS from '../images/skills/expressjs.png';
import NestJS from '../images/skills/nestjs.png';
import RabbitMQ from '../images/skills/rabbitmq.png';
import Socketio from '../images/skills/socketio.png';
import Scrum from '../images/skills/scrum.png';
import Docker from '../images/skills/docker.png';
import Tensorflow from '../images/skills/tensorflow.webp';
import NLP from '../images/skills/nlp.png';
import Langchain from '../images/skills/langchain.png';

const skills = [
    {
        id: uuidv4(),
        icon: Python,
        name:"Python",
    },
    {
        id: uuidv4(),
        icon: NLP,
        name:"NLP",
    },
    {
        id: uuidv4(),
        icon: Langchain,
        name:"Langchain",
    },
    {
        id: uuidv4(),
        icon: Tensorflow,
        name:"Tensorflow",
    },
    {
        id: uuidv4(),
        icon: Docker,
        name:"Docker",
    },
    // {
    //     id: uuidv4(),
    //     icon: R,
    //     name:"R",
    // },
    // {
    //     id: uuidv4(),
    //     icon: Selenium,
    //     name:"Selenium",
    // },
    {
        id: uuidv4(),
        icon: Soup,
        name:"Beautiful Soup",
    },
    {
        id: uuidv4(),
        icon: SQL,
        name:"SQL",
    },
    {
        id: uuidv4(),
        icon: MongoDB,
        name:"MongoDB",
    },
    {
        id: uuidv4(),
        icon: Java,
        name:"Java",
    },
    {
        id: uuidv4(),
        icon: HTML,
        name:"HTML",
    },
    {
        id: uuidv4(),
        icon: CSS,
        name:"CSS",
    },
    {
        id: uuidv4(),
        icon: JS,
        name:"JS",
    },
    // {
    //     id: uuidv4(),
    //     icon: PHP,
    //     name:"PHP",
    // },
    {
        id: uuidv4(),
        icon: Bootstrap,
        name:"Bootstrap3",
    },
    {
        id: uuidv4(),
        icon: ReactLogo,
        name:"React",
    },
    // {
    //     id: uuidv4(),
    //     icon: NodeJS,
    //     name:"NodeJS",
    // },
    {
        id: uuidv4(),
        icon: ExpressJS,
        name:"ExpressJS",
    },
    {
        id: uuidv4(),
        icon: NestJS,
        name:"NestJS",
    },
    {
        id: uuidv4(),
        icon: RabbitMQ,
        name:"RabbitMQ",
    },
    {
        id: uuidv4(),
        icon: Socketio,
        name:"Websocket",
    },
    {
        id: uuidv4(),
        icon: C,
        name:"C",
    },
    {
        id: uuidv4(),
        icon: Arduino,
        name:"Arduino",
    },
    {
        id: uuidv4(),
        icon: UML,
        name:"UML",
    },
    {
        id: uuidv4(),
        icon: Figma,
        name:"Figma",
    },
    {
        id: uuidv4(),
        icon: GIT,
        name:"GIT",
    },
    {
        id: uuidv4(),
        icon: Scrum,
        name:"PSPO1",
    },

    

]

export default skills;